import React from 'react';
import styles from './monobot.module.scss';
import { IntroSection } from '../../components/MonobotComponents/IntroSection';
import StrengthsSection from '../home2/subcomponents/StrengthsSection';
import Speed from '../../assets/monobot/Gauge.svg';
import HandTap from '../../assets/monobot/HandTap.svg';
import Presentation from '../../assets/monobot/PresentationChart.svg';
import Gauge from '../../assets/monobot/whatshot_FILL0_wght300_GRAD0_opsz24 (1) 1.svg';
import { InstructionsSection } from '../../components/MonobotComponents/InstructionsSection';
import { PlansSection } from '../../components/MonobotComponents/PlansSection';
import { Helmet } from 'react-helmet';
import GoogleTagManager from '../../components/GoogleTagManager';
import FacebookPixel from '../../components/FacebookPixel/FacebookPixel';

const strengthsList = [
  {
    id: '1',
    img: {
      src: Presentation,
      alt: 'headset',
    },
    title: `Collect your leads data automatically`,
    text: `AI Agent gathers valuable customer information during interactions, such as contact details and preferences, and stores it for future use. This data can be used for targeted marketing and lead nurturing, increasing the efficiency and effectiveness of sales strategies.`,
  },
  {
    id: '2',
    img: {
      src: Gauge,
      alt: 'code',
    },
    title: `Increase sales and conversions`,
    text: `AI Agent engages with potential customers in real-time, providing personalized and efficient assistance that leads to sales. AI Agent also offers product recommendations and upsell opportunities, ensuring higher conversion rates.`,
  },
  {
    id: '3',
    img: {
      src: Speed,
      alt: 'frame',
    },
    title: `reduce response time`,
    text: `With AI Agent, businesses can respond to customer queries and concerns immediately, without the need for human intervention. This greatly increases satisfaction and sales, resulting in reduced customer service costs for your business.`,
  },
  {
    id: '4',
    img: {
      src: HandTap,
      alt: 'PM',
    },
    title: `Set up self-service`,
    text: `By utilizing AI Agents for self-service, businesses can provide customers with the ability to get their questions answered or problems solved quickly and easily, without having to wait for a human representative.`,
  },
];
const title = 'monobot CX';
const content = 'monobot CX';
const url = window.location.href;

export const Monobot = () => {
  return (
    <div className="home page-wrapper">
      <Helmet title="Tring | monobot CX">
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={content} />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Tring",
                "item": "https://etring.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Blog",
                "item": "https://etring.com/blog/"
              }]
            }`}
        </script>
        <GoogleTagManager />
        <FacebookPixel />
      </Helmet>
      <IntroSection />
      <StrengthsSection
        strengthsList={strengthsList}
        title={'AI Agents that Works as a Customer '}
        animatedTitle={'Service Rep'}
        style={{ position: 'absolute', right: '8%' }}
        button={true}
        custom={true}
      />
      <InstructionsSection />
      <PlansSection />
    </div>
  );
};
