import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';

const HireUsButton = ({ isHeader, isFooter, toggled }) => {
  const navigateTo = useNavigate();
  const onHireUsBtnClick = () => navigateTo('/contacts');
  return (
    <Button
      headerButton={isHeader}
      footerButton={isFooter}
      toggled={toggled}
      text={'Inquire Now'}
      onClick={onHireUsBtnClick}
    />
  );
};

HireUsButton.propTypes = {
  isHeader: PropTypes.bool,
  isFooter: PropTypes.bool,
  toggled: PropTypes.bool,
};
export default HireUsButton;
