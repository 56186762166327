import PropTypes from 'prop-types';
import './category.scss';
export const CategoryBar = ({ text, onClick }) => {
  return (
    <div className="categoryWrapper" onClick={() => onClick()}>
      <p className="categoryWrapper-title">{text}</p>
    </div>
  );
};

CategoryBar.propTypes = {
  text: PropTypes.string,
  key: PropTypes.number,
  onClick: PropTypes.any,
};
