export const submitHandler = async (values) => {

  const text = values.message || values.idea

  return await fetch("https://mailing.etring.com/index.php", {
    method: 'POST',
    body: JSON.stringify({...values, text})
  }).then((response) =>  {
    if (!response.ok) throw Error(response.statusText)
    return

  }).catch(err => {
    const errText = "Unfortunately your data haven`t been sent. Try again a little bit later"
    return errText
  })

}
