import { useState } from 'react';
import { NavItem } from '../NavItem';
import { NavLink } from 'react-router-dom';

import LogoHexs from '../../assets/logo-hexs.svg';
import LogoText from '../../assets/logo-tring.svg';

import './Header.scss';
import HireUsButton from '../HireUsButton';
import Icon from '../Icon';
import { contactsList, getContactInfo } from '../../heplers/contactsList';
import FacebookPixel from '../FacebookPixel/FacebookPixel';

export const navList = [
  {
    id: '1',
    title: 'Services',
    link: '/software-development-services/',
  },
  {
    id: '2',
    title: 'genesys',
    link: '/genesys-contact-center-solutions/',
  },
  {
    id: '3',
    title: 'monobot',
    link: '/monobot/',
    labelOnTop: 'new product',
  },
  {
    id: '4',
    title: 'blog',
    link: '/blog/',
  },
  {
    id: '5',
    title: 'contact us',
    link: '/contacts/',
  },
  { id: '6', title: 'our customers', link: '/#customers' },
];

export const Header = () => {
  const [isToggled, setToggled] = useState(false);

  const handleToggle = () => {
    setToggled(prev => !prev);
  };

  const closeMenu = () => {
    setToggled(false);
  };

  const filteredContactsList = contactsList
    .map(contact => {
      if (contact.id !== 'address' && contact.id !== 'share') {
        return contact;
      }
      return undefined;
    })
    .filter(x => x !== undefined);

  const { withText, withoutText } = filteredContactsList.reduce(
    (acc, item) => {
      if (item.text) {
        acc.withText.push(item);
      } else {
        acc.withoutText.push(item);
      }
      return acc;
    },
    { withText: [], withoutText: [] },
  );

  return (
    <header className="header">
      <div className="header-inner-contacts">
        <ul className="contacts">
          <div className="contacts-wrapper1">
            {withText.map(item => (
              <Icon
                key={item.id}
                text={item.text}
                id={item.id}
                href={item.href}
                subLink={item.subLink}
              />
            ))}
          </div>
          <div className="contacts-wrapper2">
            {withoutText.map(item => (
              <Icon header={true} key={item.id} text={item.text} id={item.id} href={item.href} />
            ))}
          </div>
        </ul>
      </div>
      <div className={'header-inner'}>
        <div className="logo">
          <NavLink
            to={'/'}
            className={!isToggled ? 'logo-link' : 'logo-enable'}
            onClick={closeMenu}
          >
            <img src={LogoHexs} alt="logo" />
            <img src={LogoText} alt="logo" />
          </NavLink>
        </div>
        <div className={isToggled ? 'hire-btn offButton' : 'hire-btn'}>
          <HireUsButton isHeader={false} />
        </div>
        <nav className={isToggled ? 'nav toggled' : 'nav toggled-off'}>
          <div className="toggle-btn" onClick={handleToggle}>
            <div className="toggle-bar"></div>
            <div className="toggle-bar"></div>
            <div className="toggle-bar"></div>
          </div>

          <ul className="menu">
            {navList.map(item => (
              <NavItem key={item.id} {...item} closeMenu={closeMenu} />
            ))}
            <div onClick={handleToggle}>
              <HireUsButton isHeader={!isToggled} toggled={true} />
            </div>
            <div className="sideBarItem phoneWrapper">
              <Icon
                header={true}
                text={getContactInfo('phone').text}
                id={'phone'}
                href={getContactInfo('phone').href}
                subLink={'Monobot Assistant'}
              />
            </div>
            <div className="sideBarItem socialWrapper">
              <Icon header={true} id={'linkedin'} href={getContactInfo('linkedin').href} />
              {/* <Icon id={'whatsapp'} href={getContactInfo('whatsapp').href} /> */}
              <Icon header={true} id={'facebook'} href={getContactInfo('facebook').href} />
            </div>
          </ul>
        </nav>
      </div>
      <FacebookPixel />
    </header>
  );
};
