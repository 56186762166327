import { TitleWithPartlyOverlay } from '../../../TitleWithOverlay';
import Button from '../../../Button';
import styles from './item.module.scss';
export const InstructionItem = ({
  title,
  text,
  defaultTitle,
  content,
  paragraphClass,
  button,
  addStopPoint,
}) => {
  return (
    <div
      className={`${styles.itemContainerWrapper} ${
        addStopPoint ? 'stop-point stop-point-center' : ''
      }`}
    >
      <div className={`${styles.itemContainer} ${button ? styles.itemContainer__lastSection : ''}`}>
        <div className={styles.leftContent}>
          {defaultTitle ? (
            <span className={styles.title}>{title}</span>
          ) : (
            <TitleWithPartlyOverlay
              style={{ whiteSpace: 'break-spaces' }}
              title={{
                animated: title,
              }}
            />
          )}
          {!button && <p className={'text'}>{text}</p>}
          {button && (
            <div className={styles.buttonHolder}>
              <p className={'text'}>{text}</p>
              <Button
                customClass={styles.button}
                text="Create your Ai Agent Now"
                link="https://monobot.ai/sign-up"
                blank={true}
              />
            </div>
          )}
        </div>
        <div className={styles.rightContent}>{content}</div>
      </div>
    </div>
  );
};
