import { useState, useMemo, useEffect, useRef } from 'react';
const defaultOptions = { rootMargin: '0% 0% -15% 0%' };

export const useIsInViewport = (options = defaultOptions) => {
  const ref = useRef();
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting || entry.boundingClientRect.top < 50),
        options,
      ),

    [],
  );

  useEffect(() => {
    if (!ref.current) return;
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return [ref, isIntersecting];
};

export const useIsMobile = deviceWidth => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setIsMobile(window.innerWidth <= (deviceWidth || 428));
    };

    handleWindowSizeChange();

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isMobile;
};
