import { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = props => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash !== '#customers') {
      window.scrollTo(0, 0);
      document.getElementById('root').scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return <>{props.children}</>;
};

export default ScrollToTop;
