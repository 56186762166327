import PropTypes from 'prop-types';
import './blogitem.scss';
import Icon from '../../Icon';
import { getContactInfo } from '../../../heplers/contactsList';
import { useNavigate } from 'react-router-dom';
import { CategoryBar } from '../../Category';
import LinesEllipsis from 'react-lines-ellipsis';

export const BlogItem = ({ item }) => {
  const { ID, Title, URL, SEO, Image, AltText, Category, Tags, Content } = item;

  return (
    <div className="itemWrapper" key={ID}>
      <img
        className={'itemWrapper-image'}
        id={`${Title}blogItem`}
        alt={AltText}
        src={`/blogImages/${Image}.jpg`}
      ></img>
      <div className="itemWrapper-text-content">
        <div
          // onClick={() => {
          //   navigate(`/blog${item.URL}`, { state: item.URL });
          // }}
          className="itemWrapper-title-holder"
        >
          <a href={`/blog${item.URL}`} className="itemWrapper-title">
            {Title}
          </a>
          {/* <a target="_blank" href={`/blog${item.URL}`} className="info-wrapper-bar-mobile-date">
            Read more
          </a> */}
        </div>
        {/* <a className="itemWrapper-content">{Content[1].Text}</a>{' '} */}
        <LinesEllipsis
          className="itemWrapper-content"
          text={Content[1].Text}
          maxLine="3"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>
      <div className="info-wrapper">
        <div className="info-wrapper-bar">
          <div className="info-wrapper-bar-category">
            {/* {Category.map(item => (
              <CategoryBar text={item} key={2} />
            ))} */}
            <a href={`/blog${item.URL}`} className="info-wrapper-bar-date">
              Read more
            </a>
          </div>
          {/* <span className="info-wrapper-bar-date">July 29, 2023 10:00</span> */}
        </div>
        {/* <div className="info-wrapper-button">
          <ShareButton />
        </div> */}
        {/* <div className="info-wrapper-contacts">
          <Icon id={'linkedin'} isDefault={true} href={getContactInfo('linkedin').href} />
          <Icon id={'whatsapp'} isDefault={true} href={getContactInfo('whatsapp').href} />
          <Icon id={'facebook'} isDefault={true} href={getContactInfo('facebook').href} />
          <Icon id={'share'} isDefault={true} />
        </div> */}
      </div>
    </div>
  );
};

BlogItem.propTypes = {
  item: PropTypes.any,
  key: PropTypes.number,
};
