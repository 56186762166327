export const shortenFileName = ({fileName, neededMaxSymbolAmount= 20, symbolsAmountBeforeExtension = 2}) => {
  const fileNameLength = fileName.length
  //case 1: length of file name <= symbols amount we needed
  if (fileNameLength <= neededMaxSymbolAmount) return fileName

  const dotsAmount = 3
  const fileExtention = fileName.substring(fileName.lastIndexOf('.') - symbolsAmountBeforeExtension);

  const allowableLeftSymbolAmount = neededMaxSymbolAmount - fileExtention.length - dotsAmount

  //case 2: at least 3 first symbol of file name  and file extention should be visible
  //if true , we can add dots between beginning of file name and file extention
  let beginning = ""
  if(allowableLeftSymbolAmount >= 3) {
    beginning = fileName.substring(0, neededMaxSymbolAmount - fileExtention.length - dotsAmount )
    return `${beginning}...${fileExtention}`
  }

  //case 3: above-mentioned cases does not suit. Set just first symbol of file name
  beginning = fileName.substring(0, 1)
  return `${beginning}...${fileExtention}`


}