import React from "react";
import PropTypes from "prop-types";

//for components/tags which need to have parent wrapper tag in order to hide their movement (transform: translate) or having liner-gradient overlay for animation
//look at _animation.scss classes "slide-down" or "slide-up"

//1.put rendered child component as a return of function, provided to prop "renderedChild"
//2.add to rendered child component className provided as a param from func renderedChild.
//      It is needed if order to animate child
//      We can add any class to child component to animate it
//3.clarify animationType (slide) and animationDirection (down or up). See _animation.scss classes "slide-down" or "slide-up"
//4.add "additionalClass" to specify class which fire animation. Put condition inside
//5.could add ref.
//      It is needed , if "additionalClass" based on condition: useInViewPort.
//      It is not mandatory

const AnimatedWrapper = React.forwardRef(({renderedChild, additionalClass,  animationType, animationDirection}, ref) => {
  return (
    <div className={`animation-wrapper ${additionalClass ? additionalClass : ""} ${animationType}-${animationDirection}`} ref={ref}>
      {renderedChild({childClass: "animated-child"})}
    </div>
  )
});

export default AnimatedWrapper

AnimatedWrapper.propTypes = {
  renderedChild: PropTypes.func.isRequired, //return child component
  additionalClass: PropTypes.string,  //if it is needed to make some outer styling: marging, width etc OR //add conditios for adding class, which fire animation
  animationType: PropTypes.string.isRequired,
  animationDirection: PropTypes.string.isRequired,
}