import styles from './images.module.scss';
export const ImagesItem = ({ frontImg, backImg, bothImages }) => {
  return (
    <div className={`${styles.imagesHolder} ${!bothImages ? styles.imagesHolder__single : ''}`}>
      {!bothImages ? (
        <img className={styles.singleImage} src={backImg} />
      ) : (
        <>
          {backImg && <img className={styles.backImage} src={backImg} />}
          {frontImg && <img className={styles.frontImage} src={frontImg} />}
        </>
      )}
    </div>
  );
};
