import { useIsInViewport } from '../../../heplers';
import { titleBgColor, TitleWithPartlyOverlay } from '../../../components/TitleWithOverlay';
import Form from '../../../components/Form';
import AnimatedWrapper from '../../../components/animations/AnimatedWrapper';
import React from 'react';

const FormSectionGenesys = () => {
  const observerOptions = { rootMargin: '0% 0% -20% 0%' };

  const [titleRef, isTitleInViewport] = useIsInViewport();
  const [textRef, isTextInViewport] = useIsInViewport(observerOptions);
  const [formRef, isFormInViewport] = useIsInViewport(observerOptions);

  return (
    <section className="genesys-cooperation stop-point stop-point-center">
      <div
        className={`animation-wrapper self-sliding-ltr ${isTitleInViewport ? 'self-sliding-ltr-in' : 'self-sliding-ltr-out'
          }`}
        ref={titleRef}
      >
        <TitleWithPartlyOverlay
          title={{
            beforeAnimation: 'we’d provide Genesys products',
            animated: `support`,
            afterAnimation: 'you deserve',
          }}
          className={'genesys-cooperation-title'}
          bgColor={titleBgColor.orange}
        />
      </div>

      <div className="genesys-cooperation-content">
        <AnimatedWrapper
          renderedChild={({ childClass }) => (
            <p className={`genesys-cooperation-text ${childClass}`}>
              With Tring you will receive the needed support, skilled-up expert employees, and quality product
              customizing. Quality, attentive and smooth cooperation awaits for you.
            </p>
          )}
          animationType={'slide'}
          animationDirection={'up'}
          additionalClass={isTextInViewport ? 'slide-up-in' : 'slide-up-out'}
          ref={textRef}
        />

        <div
          className={`animation-wrapper genesys-cooperation-form self-sliding-rtl ${isFormInViewport ? 'self-sliding-rtl-in' : 'self-sliding-rtl-out'
            }`}
          ref={formRef}
        >
          <Form requiredAdditionalField={'message'} />
        </div>
      </div>
    </section>
  );
};

export default FormSectionGenesys;
