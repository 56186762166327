import HireUsButton from '../../../components/HireUsButton';
import { TitleWithPartlyOverlay } from '../../../components/TitleWithOverlay';
import Video from '../../../components/Video';

const StartSection = () => {
  return (
    <section className="home-start centered-block stop-point">
      <div className="home-start-inner">
        <div className={'home-start-screen'}>
          <div className={'home-start-screen-titles'}>
            <h1 className={'home-subtitle'}>our it company </h1>
            <TitleWithPartlyOverlay
              title={{
                beforeAnimation: 'delivers',
                animated: 'outsourced',
                afterAnimation: 'projects',
              }}
              className={'home-title'}
            />
          </div>
          <div className={'home-video-wrapper'}>
            <Video />
          </div>
        </div>
        <div className={'home-start-content'}>
          <p className={'home-start-content-text'}>
            Maximizing efficiency and success with Tring - your full-cycle software development
            outsourcing IT company. We can expand your team by providing dedicated resources. This
            means no extra effort or overhead.
          </p>
          <div className={'home-start-content-btn-wrapper'}>
            <HireUsButton />
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartSection;
