import { TitleWithPartlyOverlay } from '../../TitleWithOverlay';
import { ImagesItem } from './ImagesItem';
import { InstructionItem } from './InstructionItem';
import styles from './instruction.module.scss';
import ExpertMode from '../../../assets/monobot/ExpertMode.jpg';
import Templates from '../../../assets/monobot/Templates.jpg';
import Templates1 from '../../../assets/monobot/TemplatesImg.jpg';
import Templates2 from '../../../assets/monobot/Templates2.jpg';
import Chat from '../../../assets/monobot/Chat.jpg';

const instructionItems = [
  {
    title: 'Streamlined Design Process',
    text: 'With an AI Agent from Tring, designing and implementing a chatbot for your business becomes a simple and hassle-free process. The platform offers a user-friendly interface that makes the process of creating and customizing your AI Agent quick and easy.',
    content: <ImagesItem frontImg={Templates} backImg={ExpertMode} bothImages={true} />,
    defaultTitle: true,
    bothImages: true,
  },
  {
    title: 'Versatile\nTemplates',
    text: 'Tring provides a variety of ready-to-use templates to cater to the specific needs of different businesses. These templates are designed to maximize efficiency and improve customer engagement, allowing you to choose the one that best fits your industry and business goals.',
    content: <ImagesItem frontImg={Templates1} backImg={Templates2} bothImages={true} />,
    defaultTitle: false,
    bothImages: true,
  },
  {
    title: 'Easy\nIntegration &\ncustomization',
    text: 'Adding our AI Agent to your website is a simple one-click process.',
    content: <ImagesItem backImg={Chat} bothImages={false} />,
    defaultTitle: false,
    bothImages: false,
    className: styles.customParagraph,
    button: true,
  },
];

export const InstructionsSection = () => {
  return (
    <section className={`home-strengths-wrapper centered-block ${styles.flexSection}`}>
      <div className={`${styles.titleWrapper} stop-point`}>
        <TitleWithPartlyOverlay
          title={{
            beforeAnimation: 'Design and launch',
            animated: 'in minutes',
          }}
          className={'contacts-title'}
        />
        <h1 className={'home-strengths-wrapper-subtitle'}>no coding required</h1>
      </div>
      <div className={styles.content}>
        {instructionItems.map((x, index) => (
          <InstructionItem
            addStopPoint={index !== 0}
            key={index}
            title={x.title}
            text={x.text}
            content={x.content}
            defaultTitle={x.defaultTitle}
            paragraphClass={x.className}
            button={x.button}
          />
        ))}
      </div>
    </section>
  );
};
