//the same animation as in Home Page: FiguresSection
import { useIsInViewport } from '../../../heplers';
import { titleBgColor, TitleWithPartlyOverlay } from '../../../components/TitleWithOverlay';
import AnimatedWrapper from '../../../components/animations/AnimatedWrapper';

const genesysResultsList = [
  {
    id: '1',
    amount: '253',
    content: 'skilled-up expert employees',
  },
  {
    id: '2',
    amount: '15',
    content: 'projects successfully done',
  },
  {
    id: '3',
    amount: '400+',
    content: 'satisfied B2B customers',
  },
];

const ResultsSection = () => {
  const [listRef, isListInViewport] = useIsInViewport();
  const [wrapperRef, isWrapperInViewport] = useIsInViewport();

  return (
    <section className="genesys-result stop-point stop-point-center" ref={wrapperRef}>
      <AnimatedWrapper
        renderedChild={({ childClass }) => (
          <TitleWithPartlyOverlay
            title={{ beforeAnimation: 'Just take a look at the current', animated: `results` }}
            className={`genesys-result-title ${childClass}`}
            bgColor={titleBgColor.orange}
          />
        )}
        animationType={'slide'}
        animationDirection={'up'}
        additionalClass={isWrapperInViewport ? 'slide-up-in' : 'slide-up-out'}
      />

      <div className="genesys-result-content">
        <AnimatedWrapper
          renderedChild={({ childClass }) => (
            <p className={`genesys-result-text ${childClass}`}>
              Knowing the customer's differences, enables us to create an ultimate omnichannel experience for each of
              them. Our team successfully configures and customizes Genesys products to specific requirements and
              expectations. Results quantity prove it well.
            </p>
          )}
          animationType={'slide'}
          animationDirection={'up'}
          additionalClass={`genesys-result-text ${isWrapperInViewport ? 'slide-up-in' : 'slide-up-out'}`}
        />

        <ul className={`genesys-result-list flying ${isListInViewport ? ' flying-in' : ' flying-out'}`} ref={listRef}>
          {genesysResultsList.map(({ id, amount, content }) => (
            <li key={id} className="genesys-result-item">
              <p className="genesys-result-amount">{amount}</p>
              <p className="genesys-result-description">{content}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ResultsSection;
