import styles from './privacy.module.scss';
import { useRef, useState } from 'react';

const BREAK_KEY = 'BREAK_KEY';
const PRIVACY_POLICY_LINK = 'PRIVACY_POLICY_LINK';
const PRIVACY_POLICY_URL = '/privacy-policy';
const TERMS_AND_CONDITIONS_LINK = 'TERMS_AND_CONDITIONS_LINK';
const TERMS_AND_CONDITIONS_URL = '/terms-and-conditions';
const INFO_ETRING_LINK = 'info@etring.com';
const MONOBOT_LINK = 'contact@monobot.ai';

const sections = [
  {
    id: 0,
    title: 'Introduction',
    content: `Website etring.com and all of its features and web pages (“Website”) are a website owned and operated by TRING, Inc.  (“Tring,” “we,” or “us”). Tring is grateful for choosing us and respects the privacy of its customers, business partners, job applicants, and Website visitors. This Privacy Policy (“Privacy Policy,” “Policy”) is designed to inform you about how we collect, use, and disclose the personal information you provide to us. This Privacy Policy applies to your use of this Website. Please read this Privacy Policy carefully. You accept and agree to this Privacy Policy by accessing and using the Website. If you disagree with this Privacy Policy, please do not use the Website. ${BREAK_KEY} The following Privacy Policy explains who we are, how we collect, use, and disclose personal data about you, and how you can exercise your privacy rights. 
 ${BREAK_KEY} Please take the time to read this Privacy Policy and the related statements to ensure you are fully informed. To access a specific policy, you may click on the relevant link below. Note that we may provide additional information about our privacy practices that are specific to one of our websites, events, products, or services or apply to residents of a certain jurisdiction.
 ${BREAK_KEY} If you have any questions or concerns about our use of your personal data, please contact us using the contact details provided at the bottom of this Privacy Policy.
 `,
  },
  {
    id: 1,
    title: 'Information We Collect',
    content: `Following this Privacy Policy, you don't need to register for our services or products. We do not require the submission of any information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked (directly or indirectly) with you (“Personal Information”, “Information”).  ${BREAK_KEY} However, when you visit the Website, use the functionality contained therein, and/or communicate with us via email, social media, or feedback or contact forms located on the Website, we may ask for Personal Information, such as your name, address, phone number, that will be used for purposes set out in this Privacy Policy.
 ${BREAK_KEY} Some information is also automatically collected through cookies, web beacons, and other tracking technologies, such as your Internet Protocol (IP) address, your operating system, the browser type, the address of a referring website, your user preferences, and your activity on the Website to enhance your user experience. We treat this information as Personal Information if we combine it with or link it to any of the identifying Personal Information mentioned above.
 ${BREAK_KEY}  When you commence direct communications with us, we may retain such communications and the accompanying Personal Information to process your inquiries, respond to your requests, and improve our services. The Personal Information we collect depends on the context of your interactions with us and the choices you make. ${BREAK_KEY} We collect Personal Information from you through our interactions with you, through our services, and from third parties or information that is publicly available.
 `,
    list: [
      {
        subItems: [
          { text: 'Complete and submit information or forms on our Website.' },
          { text: 'Download content on our Website.' },
          { text: 'Provide us with feedback and information via phone, email, social media, etc.' },
          { text: 'Answer a survey.' },
          { text: 'Purchase or use our products and/or services.' },
          {
            text: 'During in-person meetings or at our events, conferences, workshops, or gatherings.',
          },
          { text: 'Post material to our Website for consideration for employment.' },
        ],
      },
    ],
  },
  {
    id: 2,
    title: `How We Use Your Information`,
    list: [
      {
        subItems: [
          {
            text: 'To personalize the user’s experience and to allow us to deliver the type of content and product offerings in which you are most interested.',
          },
          { text: 'To operate, maintain, and improve our Website, services, and products.' },
          {
            text: 'To provide you with information about products or services that you request from us.',
          },
          { text: 'To deliver services or products that you request, or purchase.' },
          {
            text: `To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including without limitation, our Website ${TERMS_AND_CONDITIONS_LINK}.`,
          },
          { text: 'To notify you about changes to our Website or obtain any required consent.' },
          {
            text: 'For industry analysis, benchmarking, analytics, marketing, and other business purposes.',
          },
          {
            text: 'To track your browsing behavior, such as the pages you visited over time, for analytics and advertising purposes.',
          },
          { text: 'To contact you for marketing purposes.' },
          { text: 'To promote our products and services on our social media accounts.' },
          {
            text: 'To consider you for employment, if you have provided information for such purpose.',
          },
          {
            text: 'To comply with legal requirements and to prevent activities that may violate our policies.',
          },
        ],
        contentAfterItems: {
          text: `You can withdraw consent at a later time by contacting us using the contact details provided at the bottom of this Privacy Policy. For more information, see the section on “Your Rights And Choices“.`,
        },
      },
    ],
    content: `The Personal Information we collect allows us to keep you posted on our latest product announcements, software updates, and special offers. We may process Personal Information that we collect about you for the following purposes:`,
  },
  {
    id: 3,
    title: `Sharing Your Information`,
    content: `We may share your Personal Information with the succeeding parties and as otherwise described in this Privacy Policy or during the time of collection: `,
    list: [
      {
        subItems: [
          {
            text: `Third-Party Partners. We may share your Information with trusted third-party service providers to assist us in delivering our products and services, or our business, such as customer service, hosting, information technology, and website analytics. We will provide those companies with only the information they need to deliver the service.
    We are not responsible for the actions of any independent third parties you may choose to engage with directly, nor are we responsible for any additional information you provide directly to any third parties, and we encourage you to become familiar with their privacy practices before disclosing information directly to any such parties.
    `,
          },
          {
            text: `Legal Compliance. We may disclose your information to comply with legal obligations, protect our rights, and respond to legal requests.
    `,
          },
          {
            text: `Business acquirers. Relevant participants in potential transactions or business transactions involving an acquisition, a merger, consolidation, corporate divestiture, reorganization, sale, or other disposition of all or any portion of the assets of the business, or equity interests (including, in connection with a bankruptcy or similar proceedings).`,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: `Your Rights and Choices`,
    content: `Under the stipulated conditions, you can exercise all of the rights listed below, which are granted to you by legislation on the protection of Personal Information, based on user location:`,
    list: [
      {
        subItems: [
          {
            text: 'Access. You have the right to access your Personal Information and request further details about how it is processed.',
          },
          {
            text: 'Rectification. You have the right to request the correction of any inaccurate or incomplete Personal Information we hold about you.',
          },
          {
            text: 'Erasure. You have the right to request the deletion of your Personal Information under certain circumstances, such as when it is no longer necessary for the purposes for which it was collected.',
          },
          {
            text: 'Restriction. You have the right to request the restriction of processing of your Personal Information in certain situations, such as when you contest the accuracy of the data.',
          },
          {
            text: 'Data Portability. You have the right to receive your Personal Information in a structured, commonly used, and machine-readable format and to transmit it to another controller where technically feasible.',
          },
          {
            text: 'Objection. You have the right to object to the processing of your Personal Information, including for direct marketing purposes, where applicable.',
          },
          {
            text: 'Withdrawal of Consent. If you have provided consent for the processing of your Personal Information, you have the right to withdraw your consent at any time. Withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal.',
          },
          {
            text: 'Complaint. You have the right to complain to a supervisory authority if you believe that our processing of your Personal Information violates applicable data protection laws.',
          },
        ],
        contentAfterItems: {
          text: `You may exercise these rights by contacting us using the contact details provided at the bottom of this Privacy Policy. We may need to verify your identity before processing your request, and we may refuse requests that are manifestly unfounded or excessive. ${BREAK_KEY} Please note that certain rights may be subject to limitations or exceptions under applicable laws. For example, we may retain certain information for compliance with legal obligations or defense against legal claims.
 ${BREAK_KEY} If you are located in the European Union (EU) or the European Economic Area (EEA), GDPR rights apply to you. We are committed to facilitating the exercise of your rights by applicable data protection laws.`,
        },
      },
    ],
  },
  {
    id: 5,
    title: `Security Measures`,
    content: `We are committed to protecting the security of your Personal Information and we honor your choices for its intended use. We implement reasonable security measures to protect your Personal Information from unauthorized access, disclosure, alteration, and destruction. However, it is also important to note that security risk is inherent in all internet and information technologies, and we are unable to guarantee that your personal information is secured. Please remember that you control what personal information you provide while using the Website. Always be careful and responsible regarding your personal information.`,
  },
  {
    id: 6,
    title: `Children's Privacy`,
    content: `The Website is not intended for use by individuals under the age of 18. We do not offer our products or services for use by children. If you are under 18, you may not use the Website or our products and services without the involvement of a parent or a guardian. ${BREAK_KEY} We do not knowingly collect Personal Information from children. If we know that we have gathered Personal Information from a child under 18 without the parent’s or guardian’s consent as required by law, we will delete it. ${BREAK_KEY} If you believe we have mistakenly or unintentionally collected the Personal Information of a minor without appropriate consent please contact us using the contact details provided at the bottom of this Privacy Policy and we will take steps to delete their Personal Information from our systems.`,
  },
  {
    id: 7,
    title: `Notification of Changes to this Privacy Policy`,
    content: `This Privacy Policy was last updated in 01.04.2024. This Privacy Policy may be updated periodically to reflect changes in our practices, technologies, operational requirements, and industry practices and to be compliant with applicable privacy and data protection laws and regulations. We expect most such changes to be minor. Future changes made to this Privacy Policy are published on our Website, therefore, we ask you to check back periodically for the most updated version of the Privacy Policy. By continuing to use our Website, you accept those changes. If you have any questions regarding this Privacy Policy, please contact us using the contact details provided at the bottom of this Privacy Policy.`,
  },
  {
    id: 8,
    title: `Contact Us`,
    content: `If you have questions about this Privacy Policy or our ${TERMS_AND_CONDITIONS_LINK}, please contact us at info@etring.com at any time.`,
  },
];

export const PrivacyPolicy = () => {
  const [selectedId, setSelectedId] = useState(-1);
  const scrollToElement = id => {
    const element = document.getElementById(id.toString());
    const headerHeight = 120;

    if (element) {
      const rootElement = document.getElementById('root');
      rootElement.scrollTo({
        top: element.offsetTop - headerHeight,
        behavior: 'smooth',
      });
      setSelectedId(id);
    }
  };

  const renderCustomSlideDots = item => (
    <li className={`${selectedId === item.id ? 'slick-active' : ''}`}>
      <div className="slick-dot-wrapper" style={{ textWrap: 'wrap' }}>
        <p
          className="slick-title"
          style={{ textTransform: 'initial' }}
          onClick={() => {
            scrollToElement(item.id);
          }}
        >
          {item.id + 1}. {item.title}
        </p>
        <div className="slick-dot-decorator" />
      </div>
    </li>
  );

  const processContent = content => {
    if (!content) return null;

    return content.split(BREAK_KEY).map((text, idx) => (
      <p key={idx} className={styles.textContent}>
        {text.split(/STRONG_KEY\[(.*?)\]/g).map((subPart, subIdx) => {
          if (subIdx % 2 === 1) {
            return (
              <span className={styles.strongText} key={subIdx}>
                {subPart}
              </span>
            );
          }

          const replacedText = subPart
            .split(
              new RegExp(
                `(${TERMS_AND_CONDITIONS_LINK}|${INFO_ETRING_LINK}|${MONOBOT_LINK}|${PRIVACY_POLICY_LINK})`,
              ),
            )
            .map((part, partIdx, arr) => {
              if (part === TERMS_AND_CONDITIONS_LINK) {
                return (
                  <a
                    key={partIdx}
                    href={TERMS_AND_CONDITIONS_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.privacyPolicyLink}
                  >
                    Terms and Conditions
                  </a>
                );
              } else if (part === INFO_ETRING_LINK) {
                return (
                  <a
                    key={partIdx}
                    href={`mailto:${INFO_ETRING_LINK}`}
                    className={styles.privacyPolicyLink}
                  >
                    {INFO_ETRING_LINK}
                  </a>
                );
              } else if (part === MONOBOT_LINK) {
                return (
                  <a
                    key={partIdx}
                    href={`mailto:${MONOBOT_LINK}`}
                    className={styles.privacyPolicyLink}
                  >
                    {MONOBOT_LINK}
                  </a>
                );
              } else if (part === PRIVACY_POLICY_LINK) {
                return (
                  <a
                    key={partIdx}
                    href={PRIVACY_POLICY_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.privacyPolicyLink}
                  >
                    Privacy Policy
                  </a>
                );
              } else {
                return part;
              }
            });

          return replacedText;
        })}
      </p>
    ));
  };

  return (
    <div className={`home-start centered-block ${styles.container}`}>
      <div className={styles.mainWrapper}>
        <h1 className="title home-title">Privacy Policy</h1>
        <div className={styles.subWrapper}>
          <div className={styles.wrapper}>
            {sections.map((term, index) => (
              <div id={term.id.toString()} className={styles.termItem}>
                <div className={styles.contentWrapper}>
                  <p className={styles.title}>{`${index + 1}. ${term.title}`}</p>
                  {processContent(term.content)}
                  {term.list &&
                    term.list.map((listItem, idx) => (
                      <div key={idx} className={styles.termListItem}>
                        {listItem.itemTitle && (
                          <p className={styles.subTitle}>{`${index + 1}.${idx + 1} ${
                            listItem.itemTitle
                          }`}</p>
                        )}
                        <div className={styles.textContainer}>
                          {processContent(listItem.itemContent)}
                        </div>
                        <div className={styles.subItemsHolder}>
                          {listItem.subItems && (
                            <ul className={styles.policyUL}>
                              {listItem.subItems.map((subItem, subIdx) => (
                                <li key={subIdx}>
                                  {subItem.strongText ? (
                                    <span className={styles.strongText}>{subItem.strongText}</span>
                                  ) : null}{' '}
                                  {processContent(subItem.text)}
                                </li>
                              ))}
                            </ul>
                          )}
                          {listItem.contentAfterItems && (
                            <div className={styles.textContainer}>
                              {processContent(listItem.contentAfterItems.text)}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>

          <div style={{ margin: 0 }} className={`home-customers-slider ${styles.sideBar}`}>
            <span className={styles.dotsTitle}>TABLE OF CONTENT</span>
            <ul
              className="slick-dots-custom"
              style={{ position: 'initial', display: 'flex', flexDirection: 'column' }}
            >
              {sections.map(item => renderCustomSlideDots(item))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
