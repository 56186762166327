import {useIsInViewport} from "../../../heplers";
import {titleBgColor, TitleWithPartlyOverlay} from "../../../components/TitleWithOverlay";
import AnimatedWrapper from "../../../components/animations/AnimatedWrapper";
import React from "react";

const servicesList = [
  {
    id: "1",
    title: "initial configuration",
    content: "Solution for initial configuration and management of core entities required for contact center functionality.",
    imgClass: "config"
  },
  {
    id: "2",
    title: "outgoing interactions",
    content: "Solution for configuration, scheduling, ad execution of outgoing interactions on several digital channels.",
    imgClass: "interaction"
  },
  {
    id: "3",
    title: "managing workforce",
    content: "Solutions for contact centers administrators allow them to manage workforce and staffing plan.",
    imgClass: "workforce"
  },
  {
    id: "4",
    title: "tools for agents",
    content: "Tools for contact center agents: widgets, adapters, web phone, integrations with messengers and other.",
    imgClass: "tools"
  }
]

const ServicesSection = () => {
  const [listWrapperRef, isListWrapperInView] =  useIsInViewport()
  const [textColRef, isTextColInView] =  useIsInViewport()

  return (
    <section className="genesys-services stop-point stop-point-center">
      <div className="genesys-services-col" ref={textColRef}>
        <AnimatedWrapper
          renderedChild={({childClass}) => (
            <TitleWithPartlyOverlay
              title={{beforeAnimation: "Tring team", animated: `develops`, afterAnimation: "from scratch"}}
              className={`genesys-services-title ${childClass}`}
              bgColor={titleBgColor.orange}
            />
          )}
          animationType={"slide"}
          animationDirection={"up"}
          additionalClass={isTextColInView ? "slide-up-in" : "slide-up-out"}
        />

        <AnimatedWrapper
          renderedChild={({childClass}) => (
            <p className={`genesys-services-context ${childClass}`}>
              And provide support, testing, and delivery of different Genesys products. Here is a list of the different solutions we offer our customers.
            </p>
          )}
          animationType={"slide"}
          animationDirection={"up"}
          additionalClass={isTextColInView ? "slide-up-in" : "slide-up-out"}
        />

      </div>

      <div className={"genesys-services-col"} ref={listWrapperRef}>
        <ul className={`genesys-services-list appear ${isListWrapperInView ? "appear-in" : "appear-out"}`} >
          {
            servicesList.map(({id, title, content, imgClass} )=> (
              <li key={id} className="genesys-services-item">
                <div>
                  <div className={`svg ${imgClass}`} />
                </div>
                <div className="genesys-services-item-content">
                  <h3>{title}</h3>
                  <p>{content}</p>
                </div>
              </li>
            ))
          }
        </ul>
      </div>

    </section>
  )
}

export default ServicesSection
