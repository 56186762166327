import { FooterItem } from '../FooterItem';
import { useLocation } from 'react-router-dom';
import LogoHexs from '../../assets/logo-hexs.svg';
import LogoText from '../../assets/logo-tring.svg';
import Icon from '../Icon';
import { getContactInfo } from '../../heplers/contactsList';
import HireUsButton from '../HireUsButton';
import './Footer.scss';
// import { getBlogItems } from '../../heplers/blogParser';
import data from '../../heplers/blogData';

const footerLinks = [
  {
    id: '1',
    title: 'Career',
    link: '/career/',
    isDisabled: true,
  },
  {
    id: '2',
    title: 'Terms and conditions',
    link: '/terms-and-conditions',
    isDisabled: true,
  },
  {
    id: '3',
    title: 'Privacy Policy',
    link: '/privacy-policy',
    isDisabled: true,
  },
];

const blogLinks = [
  {
    id: '1',
    title: 'Digitalized Project Management',
    link: '',
    isDisabled: false,
  },
  {
    id: '5',
    title: 'Unleash',
    link: '#',
    isDisabled: true,
  },
  {
    id: '5',
    title: '4 ways to improve',
    link: '#',
    isDisabled: true,
  },
  {
    id: '5',
    title: 'Trim Tab',
    link: '#',
    isDisabled: true,
  },
];

const servicesLinks = [
  {
    id: '5',
    title: 'Services',
    link: '/software-development-services/',
    isDisabled: true,
  },
  {
    id: '1',
    title: 'Genesys',
    link: '/genesys-contact-center-solutions/',
    isDisabled: false,
  },
  {
    id: '5',
    title: 'Contact us',
    link: '/contacts/',
    isDisabled: true,
  },
  {
    id: '5',
    title: 'Our Customers',
    link: '/#customers',
    isDisabled: true,
  },
];

export const Footer = () => {
  // const blogData = getBlogItems(data);

  const currentBlogLinks = data.slice(0, 4);
  const location = useLocation();
  const pathes = [
    'genesys',
    'software-development-services',
    'genesys-contact-center-solutions',
    'monobot',
  ];

  const addStopPoint = () => {
    const path = location.pathname.replace(/\//gi, '');
    if (!path || pathes.indexOf(path) > -1) return true;
    return false;
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer className={`footer ${addStopPoint() ? 'stop-point' : ''}`}>
      <div className="footer-inner">
        <div className="divider top"></div>
        <div className="footerWrapper">
          <div className="footerWrapper-column firstItem firstColumn">
            <div className="footerWrapper-logo">
              <img src={LogoHexs} alt="logo" />
              <img src={LogoText} alt="logo" />
            </div>
            <div className="footerWrapper-description">
              <p>
                Tring is an IT company that provides full-cycle outsourced software development
                services specializing in the areas of customer service solutions and IP telephony.
              </p>
            </div>
            <div className="footerWrapper-description-details-info">
              <div className="footerWrapper-description-details">
                <Icon
                  text={getContactInfo('address').text}
                  id={'address'}
                  footer={true}
                  href={getContactInfo('address').href}
                />
                <Icon
                  text={getContactInfo('phone').text}
                  id={'phone'}
                  footer={true}
                  href={getContactInfo('phone').href}
                />
                <Icon
                  text={getContactInfo('email').text}
                  id={'email'}
                  footer={true}
                  href={getContactInfo('email').href}
                />
              </div>
              <div className="footerWrapper-description-details-social">
                <Icon id={'linkedin'} footer={true} href={getContactInfo('linkedin').href} />
                {/* <Icon id={'whatsapp'} footer={true} href={getContactInfo('whatsapp').href} /> */}
                <Icon id={'facebook'} footer={true} href={getContactInfo('facebook').href} />
              </div>
              <div className="footerWrapper-description-details-hireButtonWrapper">
                <HireUsButton isHeader={false} isFooter={true} />
              </div>
            </div>
          </div>
          <div className="footerWrapper-column">
            <div className="secondColumn">
              <div className="labelHolder">
                <li className="labelHolder-label">Blog</li>
              </div>
              {currentBlogLinks.map((blog, index) => (
                <FooterItem
                  key={index}
                  title={blog.Title}
                  link={`/blog${blog.URL}`}
                  isDisabled={false}
                  animated={false}
                />
              ))}
            </div>
          </div>
          <div className="footerWrapper-column">
            <div className="thirdColumn">
              <div className="labelHolder">
                <li className="labelHolder-label">Quick Links</li>
              </div>
              {servicesLinks.map((blog, index) => (
                <FooterItem key={index} {...blog} animated={false} />
              ))}
            </div>
          </div>
          <div className="footerWrapper-description-details-mobileInfo">
            <div className="footerWrapper-column">
              <div className="labelHolder">
                <li className="labelHolder-label">Contacts</li>
              </div>
              <div className="footerWrapper-description-details">
                <Icon text={getContactInfo('address').text} id={'address'} footer={true} />
                <Icon
                  text={getContactInfo('phone').text}
                  id={'phone'}
                  footer={true}
                  href={getContactInfo('phone').href}
                />
                <Icon
                  text={getContactInfo('email').text}
                  id={'email'}
                  footer={true}
                  href={getContactInfo('email').href}
                />
              </div>
              <div className="footerWrapper-description-details-social">
                <Icon id={'linkedin'} footer={true} href={getContactInfo('linkedin').href} />
                {/* <Icon id={'whatsapp'} footer={true} href={getContactInfo('whatsapp').href} /> */}
                <Icon id={'facebook'} footer={true} href={getContactInfo('facebook').href} />
              </div>
              <div className="footerWrapper-description-details-hireButtonWrapper">
                <HireUsButton isHeader={false} isFooter={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-dividerHolder">
          <div className="divider"></div>
          <div className="footer-rightsHolder">
            <ul className="footer-list-copyright">
              {footerLinks.map((link, index) => (
                <FooterItem key={index} {...link} footer={true} animated={false} />
              ))}
            </ul>
            <span>{getCurrentYear()} © Tring, Inc</span>
          </div>
        </div>

        <div className="footer-mobileFooter">
          <ul className="footer-list-copyright">
            {footerLinks.map((link, index) => (
              <FooterItem key={index} {...link} footer={true} animated={false} />
            ))}
          </ul>
          <div className="divider"></div>
          <span>{getCurrentYear()} © Tring, Inc</span>
        </div>
      </div>
    </footer>
  );
};
