import {createContext, useState} from "react";
import PropTypes from "prop-types";

const {Provider, Consumer} = createContext()

const VacanciesProvider = ({children}) => {
  const [vacancies, setVacancies] = useState(null)
  const [chosenVacancy, setChosenVacancy] = useState(null)

  return (
    <Provider value={{vacancies, setVacancies, setChosenVacancy, chosenVacancy}}>
      {children}
    </Provider>
  )
}


export {VacanciesProvider, Consumer as VacanciesConsumer}

VacanciesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}